import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4-4-4\\@85% 1RM`}</p>
    <p>{`Band Face Pulls 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`800M Run`}</p>
    <p>{`20- V Ups`}</p>
    <p>{`15- S2OH (75/55)`}</p>
    <p>{`10- Ring Rows`}</p>
    <p>{`400M Run`}</p>
    <p>{`30- V Ups`}</p>
    <p>{`25- S2OH`}</p>
    <p>{`20- Ring Rows`}</p>
    <p>{`200M Run`}</p>
    <p>{`40- V Ups`}</p>
    <p>{`35- S2OH`}</p>
    <p>{`30- Ring Rows`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Kids at East today at 9:30am.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be closed on Sunday (Rest Day) for Easter.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start April 29th.  For more info
email Eric at: Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      